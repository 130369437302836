.subtotals {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  padding: var(--spacing-20) var(--page-inset);

  & span {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (--small) {
    padding: var(--spacing-20) var(--page-inset) 0;
  }
}

.clean .subtotals {
  padding: 0;
}

.giftWrapping {
  padding: var(--spacing-20) var(--page-inset);

  @media (--small) {
    padding: var(--spacing-20) var(--page-inset) var(--spacing-20)
  }
}

.divider {
  border-top: 1px solid var(--color-divider);
  width: calc(100% - (var(--page-inset) * 2));
  margin: 0 auto;
}



.cart__actions {
  border-top: 1px solid var(--color-divider);
  padding-top: var(--spacing-32);

  @media (--small) {
    margin-top: var(--spacing-32);
  }

  &__inner {
    flex-direction: column;
    gap: var(--spacing-16);
    display: flex;
    width: 100%;
    justiy-content: center;
    align-items: center;
    padding: 0 var(--page-inset);
  }
}

.checkoutFooter {
  margin-top: var(--spacing-16);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-48);
  flex-direction: column;
  width: 100%;
}

.voucher {
  border-top: 1px solid var(--color-divider);
  padding: var(--spacing-16) 0;
  margin-top: var(--spacing-16);
}

.total {
  padding-top: var(--spacing-16);
}

.summary {
  transition: opacity 500ms;

  &.loading {
    opacity: 0.3;
  }
}

.discount {
  color: #f44336;
}

.account {
  padding: var(--spacing-20) var(--page-inset) 0;
  @extend %typo-body2;

  & h3 {
    margin-bottom: var(--spacing-20);
  }

  & p {
    padding-bottom: var(--spacing-20);
  }

  & a {
    text-decoration: underline;
    color: var(--color-primary);
  }
}

.discountCode {
  display: flex;
  align-items: center;
  line-height: 1;
}

.toggle {
  padding: 7px;
  & svg {
    transform: none;
  }
}

.features {
  @extend %typo-caption2;
  text-transform: uppercase;
  margin: var(--spacing-24) 0;
  background: var(--color-tertiary);
  padding: var(--spacing-24) var(--spacing-16);
  border-radius: 4px;
  gap: var(--spacing-16);
  display: flex;

  &__featurewrap {
    display: flex;
    gap: var(--spacing-16);
    width: 100%;
    &::after {
      height: 51px;
      width: 1px;
      display: block;
      content: "";
      background-color: var(--color-divider);
    }

    &:last-child::after {
      content: none;
    }
  }

  &__feature {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: var(--spacing-12);
    text-align: center;
    justify-content: center;

    & img {
      width: 34px;
      height: 34px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
  }
}
